const About = ({ resumeData }) => {
  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img className="profile-pic" src="images/profilepic.jpg" alt="" />
        </div>
        <div className="nine columns main-col">
          <h2>About Me</h2>
          <p>{resumeData.aboutme}</p>
          <div className="row">
            <div className="columns contact-details">
              <h2>Contact Details</h2>
              <p className="address">
                <span>{resumeData.name}</span>
                <br></br>
                <span>{resumeData.address}</span>
                <br></br>
                <a href="mailto:dvir.benishay@gmail.om">
                  {" "}
                  <span>{resumeData.website}</span>
                </a>
              </p>
            </div>
          </div>
          <a
            id="downloaded"
            className="button"
            href={resumeData.resumeFile}
            download
          >
            <i className="fa fa-download"></i> Download Resume{" "}
          </a>
          <br />
          <a
            id="downloaded2"
            className="button"
            href={resumeData.resumeFile2}
            download
          >
            <i className="fa fa-download"></i> Download Resume Old Style
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
